import { formatDate } from '@/utils/auth/common'
import moneySectionInput from '../asset-change-query/components/money-section-input'

// 查询form
export const dataForm = (content) => {
  const startOptions = {
    disabledDate (time) {
      return time.getTime() <= new Date('1989-12-30')
    }
  }
  return [
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '产值变更日期区间',
      prop: 'changeTime',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        valueFormat: 'yyyy-MM-dd',
        pickerOptions: startOptions,
        clearable: false
      },
      tag: 'el-date-picker'
    },
    {
      label: '产值变化金额区间(元)',
      prop: 'intervalValue',
      attrs: {
        placeholder: '请输入',
        value1: content.queryParas.intervalValue[0],
        value2: content.queryParas.intervalValue[1],
        type: 'Y'
      },
      tag: moneySectionInput,
      on: {
        firstInput (data) {
          content.AmountArrChange(0, data)
        },
        secondInput (data) {
          content.AmountArrChange(1, data)
        }
      }
    },
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入'
      },
      isHidden: content.fold
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      attrs: {
        placeholder: '请输入'
      },
      isHidden: content.fold
    }
  ]
}
// 表格form
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '供应商名称',
      prop: 'gysName',
      'show-overflow-tooltip': true,
      align: 'left',
      minWidth: '110px'
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      'show-overflow-tooltip': true,
      align: 'left',
      minWidth: '120px'
    },
    {
      label: '合同名称',
      prop: 'contractName',
      'show-overflow-tooltip': true,
      align: 'left',
      minWidth: '120px'
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      'show-overflow-tooltip': true,
      align: 'center',
      minWidth: '120px'
    },
    {
      label: '产值变更日期',
      prop: 'changeDate',
      'show-overflow-tooltip': true,
      align: 'center',
      width: '120px',
      formatter: row => {
        return formatDate(row.changeDate, 'YY/MM/DD')
      }
    },
    {
      label: '累计完成工程产值金额(元)',
      prop: 'outputTotalAmountStr',
      'show-overflow-tooltip': true,
      align: 'right',
      width: '140px',
      formatter: row => {
        return (row.outputTotalAmountStr ? parseFloat(row.outputTotalAmountStr).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    },
    {
      label: '上一日累计产值金额（元）',
      prop: 'lastOutputTotalAmountStr',
      'show-overflow-tooltip': true,
      align: 'right',
      width: '140px',
      formatter: row => {
        return (row.lastOutputTotalAmountStr ? parseFloat(row.lastOutputTotalAmountStr).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    },
    {
      label: '较上日产值变化（元）',
      prop: 'valueChangeStr',
      'show-overflow-tooltip': true,
      align: 'right',
      width: '140px',
      formatter: row => {
        return (row.valueChangeStr ? parseFloat(row.valueChangeStr).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '80px',
      fixed: 'right'
    }
  ]
}
