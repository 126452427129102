<template>
  <div class="assetChangeQuery">
    <!-- 返回 -->
    <backtitle></backtitle>
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '120px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
    <!-- 查询条件隐藏显示按钮 -->
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        />
      </div>
    </base-form>
    <div class="tableList">
      <div class="summation">产值变化金额合计（元）：{{moneyTotal}}</div>
      <!-- 表格 -->
      <base-table
        class="main-page-table"
        :columns="columns"
        :showPage="true"
        :loadCount="loadCount"
        :queryParas.sync="queryParas"
        :api="api"
        :getApi="'detailspage'"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :dataSource.sync="tableData"
        ref="tableData"
        :webPage="false"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            @click="viewDetails(scope.row)"
            content="合同详情"
            icon="iconfont iconchakan"
          />
        </template>
      </base-table>
      <!-- 详情弹出框  -->
      <contract-details
        :visible.sync="visibleDetail"
        :name="name"
        :keyId="keyId"
        :contractId='contractId'
        type="output"
      />
    </div>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { dataForm, dataTable } from './config'
import ContractDetails from '@/pages/assets-manage/project-information/components/contract-details.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { outputApi } from '@/api/assetApi'
import Backtitle from '@/pages/business/components/backtitle.vue'
export default {
  name: 'assetChangeQuery',
  components: { BaseForm, BaseTable, IconButton, ContractDetails, BaseButton, Backtitle },
  data () {
    return {
      queryParas: {
        pageIndex: 1,
        pageSize: 10,
        gysId: '',
        changeTime: [],
        intervalValue: ['', '']
      },
      loadCount: 0,
      tableData: [],
      moneyTotal: '',
      visibleDetail: false,
      name: '',
      keyId: '',
      fold: false,
      contractId: ''
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return outputApi
    }
  },
  watch: {
    'queryParas.changeTime': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.changeStartTime = val[0]
          this.queryParas.changeEndTime = val[1]
        } else {
          this.queryParas.changeStartTime = ''
          this.queryParas.changeEndTime = ''
        }
      },
      deep: true
    },
    'queryParas.intervalValue': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.intervalStartValue = val[0]
          this.queryParas.intervalEndValue = val[1]
        } else {
          this.queryParas.intervalStartValue = ''
          this.queryParas.intervalEndValue = ''
        }
      },
      deep: true
    }
  },
  created () {
    this.queryParas.gysId = this.$route.query.gysId
    this.queryParas.changeTime = this.$route.query.changeTime
    this.queryParas.changeStartTime = this.$route.query.changeTime[0]
    this.queryParas.changeEndTime = this.$route.query.changeTime[1]
  },
  mounted () {
    this.handleFilter()
  },
  methods: {
    // 查询产值变化总计数据
    detailstotal () {
      if (!this.queryParas.gysId) return
      this.api.detailstotal(this.queryParas).then(res => {
        this.moneyTotal = res.data ? res.data.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00'
      })
    },
    // 默认当前时间
    getTime () {
      const myDate = new Date()
      const myYear = myDate.getFullYear()
      const myMonth = myDate.getMonth() + 1
      const myToday = myDate.getDate()
      const date = myYear + '-' + (myMonth > 9 ? myMonth : '0' + myMonth) + '-' + (myToday > 9 ? myToday : '0' + myToday)
      this.queryParas.changeTime.push(date)
      this.queryParas.changeTime.push(date)
    },
    // 查询增加选项的name
    changeQueryGys (data) {
      if (data) {
        const obj = this.dropOptions.filter((item) => item.keyId === data)
        this.$set(this.queryParas, 'gysName', obj[0].enterpriseName)
      } else {
        this.$set(this.queryParas, 'gysName', '')
      }
    },
    // 金额区间选取
    AmountArrChange (index, value) {
      this.$set(this.queryParas.intervalValue, index, value)
    },
    // 查询
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
      this.$nextTick(() => {
        this.detailstotal()
      })
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageIndex: 1,
        pageSize: 10,
        gysId: this.$route.query.gysId,
        changeTime: [],
        intervalValue: ['', '']
      }
      this.getTime()
    },
    // 查看明细
    viewDetails (row) {
      this.name = '合同名称：' + row.contractName
      // this.keyId = row.contractId
      this.contractId = row.contractId
      this.visibleDetail = true
    }
  }
}
</script>
<style lang="scss" src="./index.scss" scoped></style>
